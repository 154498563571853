import { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

const Layout: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	return (
		<Box>
			<Box sx={{ display: 'flex', p: 2, flex: 1 }}>
				<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }} >
					{children}
				</Box>
			</Box>
		</Box>
	)
}

export default Layout
