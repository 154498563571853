import { Route, Routes } from 'react-router-dom'

import Home from './Home'
import NotFound from './NotFound'
import Layout from './layouts/Layout'

const BaseRoutes = () => {
	return (
		<Routes>
			<Route index element={<Layout><Home /></Layout>} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default BaseRoutes
