import { BlockItem, BlockRenderer } from 'kitfit-community-common'
import { useEffect, useContext, useRef } from 'react'

import './Home.css'

import HeaderContext from './context/headerContext'

const blocks: Array<BlockItem> = [
	{
		'type': 'topheader',
		'data': {
			'text': 'How can we help?',
			'loginText': 'Login',
			'registerText': 'Register',
			'registerButtonProps': { variant: 'contained' }
		},
		'id': '853689cb-17d4-4d4c-b2f5-38577a2e646b'
	},
	{
		'type': 'search',
		'id': '526f0069-6459-42c0-b297-fbae76e45dc6',
		'data': {}
	},
	{
		'type': 'pinnedarticle',
		'data': {
			'articles': [
				{
					'articleId': '7',
					'imageId': '4Q7d1OxPnQRU0QXYa5m1',
					'title': 'Getting Started',
					'description': 'Articles to get you up and running quickly and easily.'
				},
				{
					'articleId': '8',
					'imageId': 'Q6mERzyRimxZJUtuJRGQ',
					'title': 'API Documentation',
					'description': 'Detailed documentation on using out API (Application Programming Interface).'
				},
				{
					'articleId': '9',
					'imageId': '95J6hyrxIm5DFBsJxm01',
					'title': 'Technical Support',
					'description': 'Get help with all your technical issues and questions.'
				},
				{
					'articleId': '10',
					'imageId': 'KFDbDl7xcvR2WQSneAa8',
					'title': 'Billing and Refunds',
					'description': 'A list of articles to help resolve your billing queries.'
				}
			]
		},
		'id': '1c144e14-c59b-4ff1-a25e-7bb0dcb04aae'
	},
	{
		'type': 'populararticle',
		'data': {
			'articles': [
				{
					'articleId': '1',
					'title': 'Cancelling or pausing a Beans pro subscription',
					'description': 'Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit.'
				},
				{
					'articleId': '2',
					'title': 'Managing and deleting teams',
					'description': 'Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Cras ultricies ligula sed.'
				},
				{
					'articleId': '3',
					'title': 'Missing orders',
					'description': 'Curabitur aliquet quam id dui posuere blandit. Mauris blandit aliquet elit, eget tincidunt nibh.'
				},
				{
					'articleId': '4',
					'title': 'Something else related to grinders',
					'description': 'Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus.'
				},
				{
					'articleId': '5',
					'title': 'Another thing about beans',
					'description': 'Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Pellentesque in ipsum id orci porta.'
				},
				{
					'articleId': '6',
					'title': 'Too much coffee or barely enough?',
					'description': 'Donec sollicitudin molestie malesuada. Donec sollicitudin molestie malesuada. Mauris blandit.'
				}

			]
		},
		'id': '07c3a32d-6597-4412-a7c2-5e5c908c35f5'
	},
	{
		'type': 'popularquestion',
		'data': {
			'numberOfQuestions': 5
		},
		'id': '7d0250b5-0e41-4a38-8d87-d954fb2bf475'
	}
]

const Home = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('Home')
	}, [])

	return (
		<BlockRenderer blocks={blocks} />
	)
}

export default Home
