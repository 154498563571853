import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { TranslatifyProvider } from 'react-translatify-next'
import { useFirebaseAuth } from 'kitfit-community-common'

import ThemeContext from './context/themeContext'
import Routes from './Routes'
import Theme from './Theme'
import { PortalCommunityContextProvider } from './context/PortalCommunityContext'

const App = () => {
	const user = useFirebaseAuth()
	const [pathname, setPathname] = useState<string>()
	const [locationSearch, setLocationSearch] = useState<string>()
	const [light, setLight] = useState(true)

	const location = useLocation()

	useEffect(() => {
		//	TODO: Can use for page views analytics
		//	console.log('send', 'pageview', location)
		if (pathname !== location.pathname) {
			setPathname(location.pathname)
		}
		if (locationSearch !== location.search) {
			setLocationSearch(location.search)
		}
		//	Use this to set any values you want defaulted on location change.
	}, [location, locationSearch, pathname])

	if (user?.authenticated === undefined) return null

	//	TODO: If we want: make a dark theme that works.
	//	Set the scheme colour based on the OS - falls back to Light if no match
	// useEffect(() => {
	// 	setLight(!window.matchMedia('(prefers-color-scheme: dark)').matches)
	// }, [])

	return (
		<ThemeProvider theme={light ? Theme.light : Theme.dark}>
			<CssBaseline />
			<ThemeContext.Provider value={{ theme: light, setTheme: setLight }}>
				<TranslatifyProvider>
					<PortalCommunityContextProvider>
						<Routes />
					</PortalCommunityContextProvider>
				</TranslatifyProvider>
			</ThemeContext.Provider>
		</ThemeProvider >
	)
}

export default App
