import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import CommunityContext, { CommunityInterface, CommunityType, useCommunityContextValue } from 'kitfit-community-common/context/CommunityContext'
import { collection, doc, getFirestore, onSnapshot, query, where } from 'firebase/firestore'

const defaultHostname = 'community.beanpunks.com' // TODO: this is for testing only (use real default host in future)
const defaultImageHostname = 'img.beanpunks.com'
const hostname = window.location.hostname !== 'localhost' ? window.location.hostname : defaultHostname

type PortalCommunityInterface = CommunityInterface

const PortalCommunityContext = React.createContext<PortalCommunityInterface | undefined>(undefined)

const usePortalCommunityContextValue = () => {
	const [community, setCommunity] = useState<CommunityType>()

	const [communityId, setCommunityId] = useState<string>()
	// get the communities (just using the first one) for this hostname
	useEffect(() => {
		console.log('hostname', hostname)
		setCommunityId(undefined)
		const db = getFirestore()
		return onSnapshot(
			query(collection(db, '/communities'), where('hostname', '==', hostname)),
			(communitiesQuerySnap) => {
				console.log('PortalCommunityContext', `got ${communitiesQuerySnap.docChanges().length} communities for hostname:${hostname}`, communitiesQuerySnap.docChanges())
				if (communitiesQuerySnap.docs.length >= 1) {
					console.log('PortalCommunityContext', `using ${communitiesQuerySnap.docs[0].id} community for hostname:${hostname}`)
					setCommunityId(communitiesQuerySnap.docs[0].id)
				} else {
					setCommunityId(undefined)
				}
			})
	}, [])
	// get the community details (once we have a communityId)
	useEffect(() => {
		setCommunity(undefined)
		if (communityId) {
			const db = getFirestore()
			return onSnapshot(
				doc(db, '/communities', communityId),
				(communitiesDocSnap) => {
					const community = communitiesDocSnap.data() as CommunityType
					community.hostname = community.hostname || defaultHostname
					community.imageHostname = community.imageHostname || defaultImageHostname
					console.log('PortalCommunityContext', `got community data for communityId:${communityId}`, community)
					setCommunity(community)
				})
		}
	}, [communityId])

	const login = useCallback(() => {
		console.log('Login')
	}, [])

	const register = useCallback(() => {
		console.log('Register')
	}, [])

	const search = useCallback((query: string) => {
		console.log('Search', { query })
	}, [])

	const value = useMemo(() => ({
		community,
		login,
		register,
		search
	}), [community, login, register, search])
	return value
}

export const PortalCommunityContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const portalCommunityContext = usePortalCommunityContextValue()
	const baseCommunityContextValue = useCommunityContextValue(portalCommunityContext)

	const communityContextValue = { ...baseCommunityContextValue, ...portalCommunityContext }

	return (
		<PortalCommunityContext.Provider value={communityContextValue}>
			<CommunityContext.Provider value={communityContextValue}>
				{children}
			</CommunityContext.Provider>
		</PortalCommunityContext.Provider>
	)
}

export const usePortalCommunityContext = () => {
	const portalCommunityContext = useContext(PortalCommunityContext)

	if (portalCommunityContext === undefined) {
		throw new Error('PortalCommunityContext was used outside of a provider')
	}

	return portalCommunityContext
}

export default PortalCommunityContext
